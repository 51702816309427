<template>
  <div v-loading="loading">
    <div class="pd-x-5">
      <el-row :gutter="30" class="is-flex js-center" style="margin-top: 10vh">
        <el-col :span="24" :md="12">
          <el-form
            ref="form"
            label-position="top"
            label-width="100px"
            :model="form"
            :rules="rules"
          >
            <el-form-item label="Username" prop="username">
              <!-- <el-input
                v-model="form.username"
                @keyup.native.13="Login"
              ></el-input> -->
              <el-select
                v-model="form.username"
                placeholder="Select"
                class="w-100"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input
                v-model="form.password"
                type="password"
                @keyup.native.13="Login"
              ></el-input>
            </el-form-item>
            <el-button type="primary" class="font-16 w-100" @click="Login"
              >Login</el-button
            >
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      tempPassword: "",
      loading: false,
      form: {
        username: "superAdminExac@gmail.com",
        password: "123456",
      },
      rules: {
        username: [
          {
            required: true,
            message: "กรุณากรอกยูสเซอร์เนม",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "กรุณากรอกรหัสผ่าน", trigger: "blur" },
        ],
      },
      options: [
        {
          value: "chattipong@gmail.com",
          label: "chattipong",
        },
        {
          value: "lf01",
          label: "lf01",
        },
        {
          value: "lf02",
          label: "lf02",
        },
        {
          value: "lf03",
          label: "lf03",
        },
        {
          value: "lf04",
          label: "lf04",
        },
        {
          value: "superAdminExac@gmail.com",
          label: "superAdminExac",
        },
        {
          value: "adminExac@gmail.com",
          label: "adminExac",
        },
        {
          value: "superAdminIt@gmail.com",
          label: "superAdminIt",
        },

        {
          value: "admin01",
          label: "adminTest",
        },
        {
          value: "supportIt@gmail.com",
          label: "supportIt",
        },
        {
          value: "credit@gmail.com",
          label: "credit",
        },
        {
          value: "insurance@gmail.com",
          label: "insurance",
        },

        {
          value: "audit@gmail.com",
          label: "audit",
        },
        {
          value: "offset001@gmail.com",
          label: "offset001",
        },
      ],
      sPhrase: process.env.VUE_APP_SECRET_KEY,
    };
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      pageActive: (state) => state.pageActive,
    }),
  },
  methods: {
    Login() {
      if (this.tempPassword == "") {
        this.tempPassword = this.CryptoJS.AES.encrypt(
          this.form.password,
          this.sPhrase
        ).toString();
      }
      this.$refs["form"].validate().then((valid) => {
        if (valid) {
          let obj = {
            username: this.form.username,
            password: this.tempPassword,
          };
          HTTP.post(`/login/dev`, obj)
            .then((res) => {
              if (res.data.success) {
                this.$store.commit("SET_USER", res.data.obj);
                this.$store.commit("SET_TOKEN", res.data.obj.token);
                this.gotoInside();
                this.loading = true;
                this.$message({
                  message: "เข้าสู่ระบบสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
              } else {
                if (res.data.status == 401) {
                  if (res.data.data == "The email or password is incorrect.") {
                    this.$message({
                      message: "ชื่อผู้ใช้ หรือ รหัสผ่านไม่ถูกต้อง",
                      type: "error",
                      duration: 4000,
                    });
                  } else {
                    this.$message({
                      message: res.data.data,
                      type: "error",
                      duration: 4000,
                    });
                  }
                } else {
                  this.$message({
                    message: res.data.data,
                    type: "error",
                    duration: 4000,
                  });
                }
              }
            })
            .catch((e) => {
              if (e.response.data.status == 401) {
                this.$message({
                  message: "ชื่อผู้ใช้ หรือ รหัสผ่านไม่ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
                // this.checkAuth();
              } else if (e.response.data.status == 403) {
                this.$message({
                  message: "คุณไม่มีสิทธิเข้าถึง",
                  type: "error",
                  duration: 4000,
                });
              } else {
                this.alertCatchError(e);
              }
            });
        }
      });
    },
    gotoInside() {
      let userLevel = this.$store.state.user.role.roleLevel;
      if (userLevel > 40) {
        if (userLevel == 70) {
          this.$router.push(`/manage/credit`);
        } else if (userLevel == 60) {
          this.$router.push(`/manage/export-insurance`);
        } else if (userLevel == 50) {
          this.$router.push(`/manage-admin/add-role`);
        } else {
          this.$router.push(`/dashboard`);
        }
      } else {
        this.$router.push(`/manage-admin/manage-role`);
      }
    },
  },
};
</script>
